import React, { useEffect, useState } from "react";
import "../../styles/GeneralStyles/navbar.css";
import logo from "../../img/gls-logo.png";
import { NavLink } from "react-router-dom"; // Changed from Link to NavLink
import LogCustomEvent from "./LogCustomEvents";

function routeToStipUpload() {
  window.location.href = "/StipUpload";
  LogCustomEvent("Upload Docs link clicked");
}

export default function Navbar() {
  const [appNumComponent, setAppNumComponent] = useState(null);
  let appNum = sessionStorage.getItem("FS_APPNUM");

  useEffect(() => {
    if (appNum && appNum !== "None") {
      setAppNumComponent(
        <div className="application-number">
          Application: <strong>{appNum}</strong>
        </div>
      );
    } else {
      setAppNumComponent(null);
    }
  }, [appNum]);

  let homeRoute = "/home";
  return (
    <div className="nav">
      <header className="nav-header">
        <nav className="navbar">
          <div className="first-section">
            <div className="navbar-brand">
              <NavLink to="https://www.glsauto.com">
                <img src={logo} alt="gls-logo" className="logo-image" />
              </NavLink>
              <h1 className="gls-logo-name">DEALER PORTAL</h1>
              {/* <img src={dcLogo} alt="dealer-connect-logo" className="dealer-connect-logo-image" /> */}
              {appNumComponent}
            </div>
          </div>
          <div className="nav-links">
            <div className="link">
              <NavLink className="link-btn" to={homeRoute} style={{ textDecoration: "none" }}>
                Rehash
              </NavLink>
            </div>           
            <div className="link">
              <div className="link-btn" onClick={routeToStipUpload} style={{ textDecoration: "none", paddingTop: '2px' }}>
                  Upload Docs
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
