import React from "react";
import { Navigate } from "react-router-dom";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.reactPlugin = new ReactPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
        extensions: [this.reactPlugin],
        enableAutoRouteTracking: true,
      },
    });

    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    this.appInsights.trackException({
      exception: error,
      severityLevel: 3,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <Navigate to="/error" />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
