import "./App.css";
import { lazy, Suspense, React } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/GeneralComponents/Navbar.jsx";
import Footer from "./components/GeneralComponents/Footer.jsx";
import ErrorBoundary from "./components/ErrorBoundary";

const Home = lazy(() => import("./pages/Home.jsx"));
const StipUpload = lazy(() => import("./pages/StipUpload.jsx"));
const Error = lazy(() => import("./pages/Error.jsx"));
const Admin = lazy(() => import("./pages/Admin.jsx"));

export default function App() {

  // Take some of the query parameters from the URL and store them in session storage
  const paramsToStore = ["USER_FIRSTNM", "USER_LASTNM", "FS_DID", "FS_APPNUM", "enc_app_num"];
  const urlParams = new URLSearchParams(window.location.search);  
  const urlParamsDict = Object.fromEntries(urlParams.entries());
  for (const [key, value] of Object.entries(urlParamsDict)) {
    if (paramsToStore.includes(key)) { 
      sessionStorage.setItem(key, value);
    }
  }
  // Clear the query parameters from the URL, don't set the URL
  window.history.replaceState({}, document.title, window.location.pathname);
  
  return (
    <div className="app">
      <Router>
        <Navbar/>
        <ErrorBoundary>
          <Routes>
            <Route
              path="/home/*"
              element={
                <Suspense fallback={<>...</>}>
                  <Home/>
                </Suspense>
              }
            />
            <Route
              path="/StipUpload/*"
              element={
                <Suspense fallback={<></>}>
                  <StipUpload/>
                </Suspense>
              }
            />
            <Route
              caseSensitive path="/authenticated/admin"
              element={
                <Suspense fallback={<></>}>
                  <Admin/>
                </Suspense>
              }
            />
          </Routes>
        </ErrorBoundary>
        <Routes>
          <Route
            path="/error"
            element={
              <Suspense fallback={<></>}>
                <Error />
              </Suspense>
            }
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}
