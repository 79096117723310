import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({ config: {
  connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING
}});
appInsights.loadAppInsights();


function LogCustomEvent(eventType) {
    var FS_APPNUM = sessionStorage.getItem("FS_APPNUM");
    var FS_DID = sessionStorage.getItem("FS_DID");
    appInsights.trackEvent({name: eventType, properties: {appNumber: FS_APPNUM, dealerNumber: FS_DID}});
  }


  export default LogCustomEvent;